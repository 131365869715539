import { h, Component } from 'preact';
import { Router, route } from 'preact-router';
import { BASE_URL, SITE_URL } from "../config";
import { campaignLogger, loadAnal } from './utility';


import Header from './header';
import Footer from './footer';
import YoutubePopup from './youtubepopup';
// import CovidPopup from './covidpopup';
import ChatPopup from './chatpopup';
import CookiePopup from './cookiepopup';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Contacts from '../routes/contacts';
import Metaverse from "../routes/metaverse";
import About from '../routes/about';
import Forbes from '../routes/forbes';
import Forbesleadinsights from '../routes/forbesleadinsights';
import Forbeshrassistant from '../routes/forbeshrassistant';
import Forbesresearch from '../routes/forbesresearch';
import Omnichannel from '../routes/omnichannel';
import Chatbotstudio from '../routes/chatbotstudio';
import ChatbotstudioAi from '../routes/chatbotstudio-ai';
import ChatbotstudioHome from '../routes/chatbotstudio-home';
import ChatbotstudioVoice from '../routes/chatbotstudio-voice';
import ChatbotstudioTreeBuilder from '../routes/chatbotstudio-tree-builder';
import ChatbotstudioLiveChat from '../routes/chatbotstudio-live-chat';
import ChatbotstudioMultimedia from '../routes/chatbotstudio-multimedia';
import ChatbotstudioMultichannel from '../routes/chatbotstudio-multichannel';
import ChatbotstudioAnalytics from '../routes/chatbotstudio-analytics';
import ChatbotstudioCustomization from '../routes/chatbotstudio-customization';
import ChatbotstudioAPIs from '../routes/chatbotstudio-apis';
import ChatbotstudioPricing from "../routes/chatbotstudio-pricing";
import ChatbotstudioITA from '../routes/chatbotstudio/ita';
import Chatbotassistant from '../routes/chatbotassistant';
import Enterprisesolution from '../routes/enterprisesolution';
import Aisociallab from '../routes/aisociallab';
import Onlineleadgen from '../routes/onlineleadgen';
import Careers from '../routes/careers';
import Partners from '../routes/partners';
import Becomeapartner from '../routes/becomeapartner';
import Register from '../routes/register';
import Login from '../routes/login';
import Forgotpw from '../routes/forgotpw';
import Crisismanagement from "../routes/crisismanagement";
import Riskcalculator from "../routes/riskcalculator";
import Chatwrp from "../routes/chatwrp";
import Charts from "../routes/charts";
import Pricing from "../routes/pricing";
import Michelangelo from "../routes/michelangelo";
import Landinguniversity from '../routes/landinguniversity';
import Privacy from '../routes/privacy';
import Terms from '../routes/terms';
import Cookies from '../routes/cookies';
import Faqs from '../routes/faqs';
import WebsiteDisclaimer from '../routes/website_disclaimer';
import Becomeanexpert from "../routes/becomeanexpert";
import Experts from "../routes/experts";
import Openai from "../routes/openai";
import OpenAI from "../routes/openai";

export default class App extends Component {

	state = { user: null, cookiesClosed: false, isStudio: false };

	checkLoggedIn() {
		if (typeof window !== 'undefined') {

			this.appWrpEl = document.getElementById('appWrp');

			let xmlhttp = new XMLHttpRequest();

			let t = this;

			xmlhttp.withCredentials = true;
			xmlhttp.onreadystatechange = function() {
				if (this.readyState === 4 && this.status === 200) {
					let res = JSON.parse(this.responseText);
					if (res.s) {
						t.setState({user: res.u});
						// loadAnal(res.ai);
					}
				}
			};

			xmlhttp.open('GET', SITE_URL + 'auth/get-current-user', true);
			xmlhttp.send();
		}
	}

	cookieAcceptedCB() {
		this.checkLoggedIn();
		loadAnal();
		campaignLogger();
	}

	constructor() {
		super();

		this.appWrpEl = false;

		this.headerCBFun = this.headerCB.bind(this);
		// this.handleRouteFun = this.handleRoute.bind(this);

	}

	componentDidMount() {
		this.checkIsStudio();
		this.appWrpEl = document.getElementById('appWrp');
		if (typeof window !== 'undefined') {
			if (localStorage.getItem('cookieaccept')) {
				this.setState({cookiesClosed: true});
				this.cookieAcceptedCB();
			}
		}
	}

	checkIsStudio() {
		/*if (typeof window !== 'undefined') {
			this.setState({isStudio: window.location.href.indexOf('chatbot-studio') !== -1})
		}*/
	}

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRouteFun = e => {
		// this.currentUrl = e.url;
		this.checkIsStudio();

		// console.log('sending pageview event');
		if (typeof window !== 'undefined') window.scrollTo(0,0);
		if (process.env.NODE_ENV === 'production') {
			if (typeof gtag !== 'undefined') {
				// console.log('sending gtag', window.location.origin + e.url)
				gtag('config', 'UA-6924977-74', {
					page_location: window.location.origin + e.url
				});
			}

			/*
			doesn't need this as it already listens to history.push state change events
			https://developers.facebook.com/ads/blog/post/2017/05/29/tagging-a-single-page-application-facebook-pixel/
			if (typeof fbq !== 'undefined') {
				fbq('track', 'PageView');
			}*/
		}

		if (this.appWrpEl) this.appWrpEl.classList.remove('openMenu');
	};

	headerCB = e => {
		e.preventDefault();
		if (this.appWrpEl) {
			if (this.appWrpEl.classList.contains('openMenu'))
				this.appWrpEl.classList.remove('openMenu');
			else
				this.appWrpEl.classList.add('openMenu');
		}
	};

	closePopup() {
		this.setState({ytData: null});
	}

	acceptCookies (accept) {
		if (typeof window !== 'undefined') {
			if (accept) {
				localStorage.setItem('cookieaccept', true);
				this.cookieAcceptedCB();
			}
		}
		this.setState({cookiesClosed: true});
	}

	closeChat() {
		console.log('closing chat')
		this.setState({chatData: null});
	}

	handleYTRequest (id) {
		// console.log(id)
		this.setState({'ytData': id});
	}

	handleChatRequest (id) {
		// console.log(id)
		this.setState({'chatData': id});
	}

	render(props, state) {


		return <div id="appWrp">
			<Header cb={ this.headerCBFun } u={ state.user } isStudio={ state.isStudio } />
			<main>
				<Router onChange={ this.handleRouteFun }>
					<Home path={ BASE_URL } ytCB={ this.handleYTRequest.bind(this) } default />
					<About path={ BASE_URL + 'about/'} />
					<Metaverse path={ BASE_URL + 'products/metaverse/'} />
					<Crisismanagement path={ BASE_URL + 'products/crisis-management/'} />
					{/*<Postcovid path={ BASE_URL + 'products/post-covid/'} />*/}
					<Riskcalculator path={ BASE_URL + 'products/risk-calculator/'} />
					{/*<Covid19 path={ BASE_URL + 'products/covid19/'} />*/}
					<Chatwrp path={ BASE_URL + 'chatwrp/:chatID'} />
					<Chatwrp path={ BASE_URL + 'chatwrp'} />
					<OpenAI path={ BASE_URL + 'openai'} />
					<Forbesleadinsights path={ BASE_URL + 'products/hr-assistant' } />
					<Forbeshrassistant path={ BASE_URL + 'products/forbes-lead-insights' } />
					<Omnichannel path={ BASE_URL + 'products/omni-channel' } />
					<Chatbotstudio ytCB={ this.handleYTRequest.bind(this) } path={ BASE_URL + 'products/chatbot-studio' } />
					<ChatbotstudioITA ytCB={ this.handleYTRequest.bind(this) } path={ BASE_URL + 'products/chatbot-studio/ita' } />
					<ChatbotstudioITA ytCB={ this.handleYTRequest.bind(this) } path={ BASE_URL + 'products/chatbot-studio/ita' } />
						<ChatbotstudioHome path={ BASE_URL + 'chatbot-studio' } />
						<ChatbotstudioAi path={ BASE_URL + 'chatbot-studio/natural-language-understanding-ai-nlu' } />
						<ChatbotstudioTreeBuilder path={ BASE_URL + 'chatbot-studio/tree-builder' } />
						<ChatbotstudioVoice path={ BASE_URL + 'chatbot-studio/voice' } />
						<ChatbotstudioLiveChat path={ BASE_URL + 'chatbot-studio/live-chat-human-takeover' } />
						<ChatbotstudioMultichannel path={ BASE_URL + 'chatbot-studio/multichannel' } />
						<ChatbotstudioAnalytics path={ BASE_URL + 'chatbot-studio/analytics' } />
						<ChatbotstudioCustomization path={ BASE_URL + 'chatbot-studio/customization' } />
						<ChatbotstudioMultimedia path={ BASE_URL + 'chatbot-studio/multimedia' } />
						<ChatbotstudioAPIs path={ BASE_URL + 'chatbot-studio/apis' } />
						<ChatbotstudioPricing path={ BASE_URL + 'chatbot-studio/pricing' } />
					<Chatbotassistant ytCB={ this.handleYTRequest.bind(this) } path={ BASE_URL + 'products/chatbot-assistant' } />
					<Enterprisesolution path={ BASE_URL + 'products/enterprise-solution' } />
					<Aisociallab path={ BASE_URL + 'products/ai-social-lab' } />
					<Onlineleadgen ytCB={ this.handleYTRequest.bind(this) } path={ BASE_URL + 'products/online-lead-gen' } />
					<Careers path={ BASE_URL + 'careers' } />

					<Partners path={ BASE_URL + 'partners/list' } />
					<Becomeapartner path={ BASE_URL + 'partners/join' } />

					<Experts path={ BASE_URL + 'experts' } />
					<Becomeanexpert path={ BASE_URL + 'experts/join' } />

					<Forbes path={ BASE_URL + 'forbes-insights' } />
					<Forbesresearch path={ BASE_URL + 'forbes-insights/research' } />
					<Forbes path={ BASE_URL + 'partners/forbes-insights' } />

					<Contacts path={ BASE_URL + 'contacts' } />
					<Pricing path={ BASE_URL + 'pricing' } />
					<Register path={ BASE_URL + 'register' } />
					<Login path={ BASE_URL + 'login' } />
					<Forgotpw path={ BASE_URL + 'reset-password/:token?' } />
					<Charts path={ BASE_URL + 'charts' } />
					<Terms path={ BASE_URL + 'terms' } />
					<Privacy path={ BASE_URL + 'privacy' } />
					<Faqs path={ BASE_URL + 'faqs' } />
					<Cookies path={ BASE_URL + 'cookies' } />
					<WebsiteDisclaimer path={ BASE_URL + 'website_disclaimer' } />
					<Michelangelo chatCB={ this.handleChatRequest.bind(this) } path={ BASE_URL + 'michelangelo' } />
					<Landinguniversity path={BASE_URL + 'landings/education'} />
				</Router>
			</main>
			<Footer />
			{ state.ytData ? <YoutubePopup data={ state.ytData } cb={ this.closePopup.bind(this) } /> : '' }
			{ state.cookiesClosed ? '' : <CookiePopup cb={ this.acceptCookies.bind(this) } /> }

			{/*{ state.hideCovid ? '' : <CovidPopup cb={ this.closeCovid.bind(this) } /> }*/}
			{/*<script async src={ 'https://static.Querlo.com/js/embeds/dist/app.js' }></script><div id="QuerloEmbd" class="Querlo" data-id="LeadGenDemo" data-width="200" data-height="400" data-pos-x="right" data-pos-y="bottom" data-template="esd" data-main-color="rgb(41,149,225)" data-speaker-img={ 'https://static.Querlo.com/image_uploads/avatar1/251197_5c52cdbab7405_png.png'} data-speaker-name="Querlo" data-intro-txt="Would you like to learn more?" data-delay=""></div>*/}
			{ state.chatData ? <ChatPopup data={ state.chatData }  cb={ this.closeChat.bind(this) } /> : '' }

		</div>
	}
}
